.popups__container {
  margin: 0 auto;
  width: 600px;
  height: 550px;
  background-color: #fffdd0;
  border-radius: 1%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  align-items: flex-end;
  z-index: 3;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.head {
  margin: 20px;
}

.popups__title,
.popups__body {
  height: 52px;
  width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.popups__body {
  height: 250px;
}
/*__________buttons__________*/

.editBtn__back {
  background-color: #ec1c24 !important;
  border: none !important;
  width: 6rem;
  float: right;
  margin-right: 8rem;
}
.editBtn__edit {
  border: none !important;
  background-color: #2e8b57 !important;
  width: 6rem;
  float: left;
  margin-left: 8rem;
}
