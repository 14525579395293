* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  /* background: #efefef; */
  background-image: linear-gradient(to right, #fff, aliceblue);
}

.hide {
  pointer-events: none;
}

main {
  min-height: calc(100vh - 100px);
}
footer {
  min-height: 8rem;
  width: 100%;
  background-image: linear-gradient(to right, #2b5876, #4e4376);
  font-family: 'Courier New', Courier, monospace;
  color: #fff;
  bottom: 0rem;
}
