.popups__container {
  height: 550px;
  width: 500px;
  padding: 8px;
  background-image: linear-gradient(to right, #ffafbd, #ffc3a0);
  border-radius: 2%;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.53);
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.head {
  margin: 20px;
}

.popups__title,
.popups__body {
  height: 52px;
  width: 350px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 25px;
}

.popups__body {
  height: 250px;
}

.popups__buttons {
  display: flex !important;
  justify-content: space-around !important;
  border: none;
  font-size: 1.5rem;
  background-color: aquamarine !important;
}

.popus__close {
  border: none !important;
  background-color: #ec1c24 !important;
  width: 5rem;
}

.popus__add {
  border: none !important;
  background-color: #00438b !important;
  width: 5rem;
  margin-right: 5rem;
}

@media screen and (max-width: 800px) {
  .popups__container {
    width: 75%;
  }

  .popups__title,
  .popups__body {
    width: 75%;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
  }
}
