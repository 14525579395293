/*_______________navbar_______________*/

.main__navbar {
  height: 100px;
  width: 100%;
  background-image: linear-gradient(to right, #fff, aliceblue);
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main__addIcon,
.main__deleteAllIcon {
  height: 5rem;
  width: 5rem;
  font-size: 2rem;
  color: #00438b;
  margin-top: 0.5rem;
  cursor: pointer;
  border-radius: 15%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 2px 5px 0px,
    rgb(231, 223, 223) 0px 1px 1px 0px;
  background-image: linear-gradient(to right, #545454, #181214);
  margin-left: 30%;
  transition: 0.2s all;
}

.main__deleteAllIcon {
  color: #ec1c24;
  margin-left: 0;
  margin-right: 30%;
}

.main__addIcon:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(165, 165, 165, 0.24);
  /* Lowering the shadow */
  background-image: linear-gradient(to right, #737171, #181214);
}

.main__deleteAllIcon:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(183, 183, 183, 0.24);
  /* Lowering the shadow */
  background-image: linear-gradient(to right, #737171, #181214);
}

.main__deleteAllIcon:disabled {
  color: gray;
  pointer-events: none;
}

.main__navbar_counter {
  height: 4rem;
  width: 8rem;
  font-size: 3rem;
  font-family: 'Orbitron', sans-serif;
  font-weight: 500;
  line-height: 4rem;
  color: #24b48a;
  background-image: linear-gradient(to right, #545454, #181214);
  border-radius: 15%;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px 0px,
    rgb(231, 223, 223) 0px 1px 1px 0px;
}

@media screen and (max-width: 800px) {
  .main__addIcon,
  .main__deleteAllIcon {
    margin: 1rem;
  }
}

/*_______________card_row_______________*/

.main__cards {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

/*_______________style_for_card_______________*/
.main__card {
  position: relative;
  margin: 40px auto;
  width: 200px;
  height: 150px;
  background: #fffdd0;
  border-radius: 2px;
  align-items: flex-end;
  color: #00438b;
}

.main__card::before,
.main__card::after {
  content: '';
  position: absolute;
  bottom: 10px;
  width: 40%;
  height: 10px;
  box-shadow: 0 5px 14px rgba(0, 0, 0, 0.7);
  z-index: -1;
  transition: all 0.3s ease-in-out;
}

.main__card::before {
  left: 15px;
  transform: skew(-5deg) rotate(-5deg);
}

.main__card::after {
  right: 15px;
  transform: skew(5deg) rotate(5deg);
}

.main__card:hover::before,
.main__card:hover::after {
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
}

.main__card:hover::before {
  left: 5px;
}

.main__card:hover::after {
  right: 5px;
}

.main__card_deleteIcon {
  color: tomato;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}
.main__card__btnOpen {
  background-color: #fffdd0;
  font-size: 1rem !important;
  border: none !important;
  color: #00438b;
}

.card__body {
  --max-lines: 3;
  --line-height: 1.4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  -webkit-line-clamp: var(--max-lines);
  max-height: calc(var(--max-lines) * 1em * var(--line-height));
}

.card__body::before {
  content: '';
  position: absolute;
  height: calc(1em * var(--line-height));
  width: 100%;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(to bottom, transparent, #fffdd0);
}

/*_____________________________________________*/

/*_________________modal_______________________*/

.modal__delete {
  background-color: #ec1c24 !important;
  border: none !important;
}
.modal__close {
  border: none !important;

  background-color: #00438b !important;
}
